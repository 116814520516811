.login {
    width: auto;
    height: auto;
}

.login .content{
    padding: 3%;
    width: 30%;
    margin: 70px 35%;   
    border-width:0px;
    background-color: #d3ddff;
}

.login .content form{
    align-content: center;
    display: inline-block;
    width: 100%;
}

.login .username-email-field, .login .password-field{
    margin-bottom: 7px;
}

.login .username-email-field input, .login .password-field input{
    width: 100%;
    outline: none;
    border-width: 0px;
    padding: 2% 4%;
}

.login .submit {
    width: 100%;
    border-width: 0px;
    padding: 3% 4%;
    background-color: #96CDFF;
}

.login .submit:hover{
    background-color: #aaa9c1;
    cursor: pointer;
}

.login .page-title {
    font-weight: bold;
    font-size: 120%;
    margin-bottom: 12px;
}

.login .error-message {
    margin-top: 2px;
    color: #BD1F32;
}

.additional-options-field{
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    font-size: 0.9em;
}

.remember-me-field .title {
    display: inline-block;
}

.remember-me-field{
    order: 1;
    display: flex;
    align-items: center;
    height: 25px;
}

.remember-me-field input[type="checkbox"]{
    display: inline-block;
    margin: 1px 0 0 3px;
    width: 20px;
}

.redirect-link{
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #3e2df5;
    text-decoration: none;   
}

.redirect-link:nth-of-type(1) {  
    order: 2;   
}

.redirect-link:nth-of-type(2){
    order: 3;
}

.redirect-link:hover {
    color: #e25c3a;    
}

.google-login-button{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top:10px;
}


@media (max-width: 890px) {
    .additional-options-field{
        font-size: 0.7em;
    }    
}
