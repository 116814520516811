body {
    margin: 0px;
    background-color: #f4fbff;
}

* {
    box-sizing: border-box;
}

.error{
    width: 70%;
    margin: 0 auto;
    padding: 10px 5px;
    background-color: rgb(255, 209, 209);
    margin-top: 8px;
    border: 2px solid rgb(255, 151, 151);
    border-radius: 5px;
    font-size: 0.9em;
}