.bottom-nav-bar{
    margin-top: 15px;
    background-color: #788592;
    position: relative;
    bottom: 0;
    width: 100%;
}

.bottom-nav-bar .content {
    padding: 15px 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.legal, .information{
    margin: 0 20%;   
    width: 20%;
}

.legal div, .information div{
    cursor: pointer;
    margin-bottom: 3px;
}
