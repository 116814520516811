.recipe-results-contianer {
    width: 70%;
    margin: 0 auto;
}

.search-results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, auto);
    grid-column-gap: 1%;
    grid-row-gap: 15px;
    margin-top: 15px;
    margin-bottom: 25px;
}

.search-result {
    background-color: #E9EEFF;
    position: relative;
    display: inline-block;
    max-height: 120px;
}

.search-result:hover {
    background-color: #D2D7E7;
}

.search-result a{
    width: 100%;
}

.search-result .image {
    display: inline-block;
    max-width: 35%;
    max-height: 120px;
}

.search-result .text-container {
    display: inline-block;
    vertical-align: top;
    margin: 0px 0px 0px 3%;
    max-width: 52%;
    padding: 2%;
}

.search-result .title, .search-result .cook-time, .search-result .ingredient-count {
    text-decoration: none;
    color: black;
    font-size: 0.99vw;
}

.search-result .title {
    font-weight: bold;
    word-wrap: break-word;
    padding-bottom: 2px;
    margin-bottom: 2px;
}

.search-result .title:hover {
    text-decoration: underline;
}

.search-result .save-button {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    background-color: black;
    text-align: center;
    max-height: 30px;
    font-size: 1.7vw;
    padding: 5px;
}

.search-result .save-button:hover {
    background-color: gray;
}

.heart-icon {
    fill: transparent;
    stroke-width: 30;
    transition: all 0.5s;
    color: white;
}

.heart-icon.active {
    animation: like 0.5s 1;
    color: white;
    fill: white;
}

.temporary-search-result {
    display: inline-block;
    background-color: #E9EEFF;
    position: relative;
    height: 13.5vh;
    width: 100%;
}

.temporary-search-result .image {
    display: inline-block;
    width: 35%;
    height: 100%;
    background-color: rgb(106, 103, 103);
}

.temporary-search-result .text-container {
    padding: 2% 0;
    display: inline-block;
    vertical-align: top;
    margin: 0px 0px 0px 3%;
    width: 60%;
    height: 100%;
}

.temporary-search-result .title, .temporary-search-result .cook-time, .temporary-search-result .ingredient-count {
    height: 20%;
    margin-bottom: 2%;
    width: 30%;
    border-radius: 15px;
    background-color: #6A6767;
}

.temporary-search-result .title {
    width: 70%;
}

.react-loading-skeleton {
    display: inline-block;
    height: 100%;
    width: 100%;
}

@media (max-width: 890px) {
    .search-results {
        grid-template-columns: auto auto auto;
        grid-template-rows: repeat(auto-fit, auto);
        grid-column-gap: 1%;
    }

    .search-result {
        width: auto;
        max-height: 100%;
    }

    .search-result .image {
        display: inline-block;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .search-result .text-container {
        margin: 0;
        width: auto;
        padding: 5% 7%;
        max-width: 100%;
    }

    .search-result .title, .search-result .cook-time, .search-result .ingredient-count {
        text-decoration: none;
        color: rgb(170, 169, 193);
        font-size: 0.7em;
    }   

    .search-result .save-button {      
        font-size: 20px;
    }

    .temporary-search-result {
        width: auto;   
        height: 170px;
    }

    .temporary-search-result .image {
        height:60%;  
        width: 100%; 
    }

    .temporary-search-result .text-container {
        height: 40%;
        width: 100%; 
    }

    .temporary-search-result .title, .temporary-search-result .cook-time, .temporary-search-result .ingredient-count {
        height: 20%;
        margin-bottom: 5%;
    }
}
