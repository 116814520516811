.recipe-details .content {
    width: 70%;
    margin: 0 auto;
    background-color: #e6edfd;
}

.content .basic-information {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2%;
    padding: 3% 5% 2% 5%;
    position: relative;
}

.basic-information .text-container {
    order: 2;
    margin: 0 3%;
    width: 50%;
    font-size: 0.9em;
}

.recipe-details .text-container .title {
    font-size: large;
    font-weight: bold;
    margin-bottom: 7px;
    transition: 1s;
}

.text-container .cook-time, .text-container .servings, .text-container .cuisines {
    margin-bottom: 5px;
    transition: 1s;
}

.basic-information .image-container {
    order: 1;
    display: inline-block;
    width: 35%;
}

.basic-information .save-button {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    background-color: black;
    text-align: center;
    max-height: 30px;
    font-size: 1.7vw;
    padding: 5px;
    margin: 1%;
    cursor: pointer;
}

.image-container .image {
    object-fit: contain;
    width: 100%;
}

.basic-information .summary {
    order: 3;
    width: 100%;
    margin: 10px 0;
    display: inline-block;
    text-align: justify;
}

.recipe-details .main-body {
    overflow: hidden;
    margin-bottom: 4%;
    padding: 2% 6% 3% 6%;
    position: relative;
}

.content hr {
    width: 90%;
}

.main-body .ingredient-list {
    float: right;
    margin-left: 3%;
    margin-bottom: 3%;
    padding: 10px 25px;
    background-color: #cad6ff;
    transition: 1s;
}

.ingredient-list .title {
    font-weight: bold;
    margin-bottom: 3%;
}

.instructions-list .title {
    font-weight: bold;
    margin-bottom: 1%;
}

.instruction-details {
    margin-left: 2%;
}

.task-name {
    margin-top: 2%;
    margin-bottom: 1%;
}

.task-steps {
    margin-left: 5%;
}

.task-steps.single-task {
    margin-left: 1%;
}

.text-container .title.skeleton {
    width: 80%;
    height: 20px;
}

.text-container .cook-time.skeleton, .text-container .servings.skeleton {
    width: 30%;
    height: 15px;
    margin-bottom: 10px;
}

.basic-information .summary.skeleton {
    height: 150px;
}

.recipe-details .image-container.skeleton {
    height: 200px;
}

.ingredient-list.skeleton {
    float: right;
    margin-left: 3%;
    margin-bottom: 3%;
    height: 30vh;
    width: 20vh;
    padding: 0;
}


.task-1.skeleton {
    width: 30%;
}

.task-2.skeleton {
    width: 50%;
}

.task-3.skeleton {
    width: 60%;
}

.task-4.skeleton {
    width: 45%;
}

.task-5.skeleton {
    width: 70%;
}

.task-6.skeleton {
    width: 35%;
}

.task-7.skeleton {
    width: 45%;
}

.task-8.skeleton {
    width: 35%;
}

.task-9.skeleton {
    width: 30%;
}

.task-10.skeleton {
    width: 25%;
}

.title.skeleton {
    width: 10%;
    margin-bottom: 10px;
}

.bullet-point {
    font-size: 1.5em;
    vertical-align: -12%;
}

@media (max-width: 940px) {
    .basic-information .summary {
        font-size: 0.8em;
    }
    .text-container .cook-time, .text-container .servings, .text-container .cuisines {
        font-size: 0.8em;
    }
    .recipe-details .text-container .title {
        font-size: 1.1em;
    }
    .main-body .ingredient-list {
        width: 100%;
        float: none;
        margin: 0;
        margin-bottom: 3%;
        background-color: #e6edfd;
        padding: 0 0 5% 0;
    }
    .ingredients-container{
        margin-left: 4%;
    }
}
