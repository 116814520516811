.nav-bar {
    background-color: #dde7ffb4;

    padding: 10px 0;
}

.nav-bar-container {
    display: flex;
    
    max-width: 70%;
    margin: 0 auto;
    height: 28px;
    transition: all 1s ease;
}

.nav-button {
    background-color: #96CDFF;
    
    display: flex;
    align-items: center;
    
    height: 100%;
    padding: 0.5% 1%;
}

.nav-button:hover {
    background-color: gray;
}

.nav-button a{
    text-decoration: none;
    color: black;

    font-size: 0.85em;
    transition: all 0.5s ease;
}

.nav-button.meal-planner{
    margin-left: 5px;
}

.nav-button.login, .nav-button.logout{
    margin-left: 5px;
}

.nav-button.logout .text{
    text-decoration: none;
    color: black;

    font-size: 0.85em;
    cursor: pointer;
    transition: all 0.5s ease;
}

.nav-button.user-profile{
    margin-left: 10px;
    border-radius: 50%;
    width: 28px;
    display: inline-block;
    content: url("../profile-image.png");
    padding: 0;
}

.nav-button.profile:hover{
    border: 2px solid rgb(38, 93, 156);
}

.nav-bar.standard .register{
    margin-left: auto;
}


/* Search bar */
.search-bar {
    margin: 0px auto;
    width: 40%;
}

.search-bar form {
    position: relative;
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
}

.search-bar .input-field{
    width: 100%;
    height: 100%;
    padding: 2% 1.2%;
    border: 0px;
}

.search-bar .submit-button{
    background-color: black;

    display: flex;
    align-items: center;
    position: absolute;
    
    right: 0.5%;
    padding: 1%;
}

.search-bar .submit-button:hover{
    background-color: #707071;
}

.search-bar .magnify-icon{
    color: white;
    cursor: pointer;
}

@media (max-width: 890px) {
    .nav-bar-container {
        height: 21px;
    }
    .nav-button.profile {
        width: 21px;
    }
    .nav-button a{
        font-size: 0.6em;
    }
    .nav-button.logout .text{
        font-size: 0.6em;
    }
    .search-bar {
        width: 30%;
    }
    .search-bar .input-field::placeholder{
        font-size: 0.7em;
    }
    .nav-button.user-profile{  
        width: 20px;
    }
}