.registration {
    width: auto;
    height: auto;
}

.registration .content{
    padding: 3%;
    width: 30%;
    margin: 70px 35%;   
    border-width:0px;
    background-color: #d3ddff;
}

.registration .content form{
    align-content: center;
    display: inline-block;
    width: 100%;
}

.registration .username-field, .registration .password-field, .registration .email-address-field{
    margin-bottom: 7px;
}

.registration .username-field input, .registration .password-field input, .registration .email-address-field input{
    width: 100%;
    outline: none;
    border-width: 0px;
    padding: 2% 4%;
}

.registration .submit {
    width: 100%;
    border-width: 0px;
    padding: 3% 4%;
    background-color: #96CDFF;
}

.registration .submit:hover{
    background-color: #aaa9c1;
    cursor: pointer;
}

.registration .page-title {
    font-weight: bold;
    font-size: 120%;
    margin-bottom: 12px;
}

.registration .error-message {
    margin-bottom: 5px;
    color: #BD1F32;
}

