/* Apply Filter segment */
.search-filter-bar {
    background-color: #788592;
}

.apply-filters-bar {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    height: 48px;
    padding: 10px 0;
}

.apply-filters-bar form {
    display: flex;
    width: 80%;
    height: 100%;
}

.ingredient-filter-container, .cuisine-filter-container, .cook-time-filter-container, .nutrition-filter-container {
    display: inline-block;
    margin-right: 10px;
    height: 100%;
}

.ingredient-filter-container {
    max-width: 30%;
}

.cuisine-filter-container {
    max-width: 30%;
}

.cook-time-filter-container {
    max-width: 170px;
}

.filter {
    height: 100%;
}

.filter-prompt-button {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #212121;
    padding: 5px 7px;
    color: #d3d3d3;
    font-size: 0.9em;
    height: 100%;
    width: fit-content;
}

.counter {
    display: flex;
    justify-content: center;
    line-height: 100%;
    width: 12px;
    margin-left: 4px;
    padding: 2px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    color: black;
    font-size: x-small;
}

.filter-prompt-button:hover {
    cursor: pointer;
    background-color: #949494;
    color: rgb(32, 32, 32);
}

.filter-prompt-button .value {
    display: inline-block;
}

.ingredient-filter-container .input-field, .cuisine-filter-container .input-field {
    display: inline-block;
    border-width: 0px;
    padding: 6px 5px;
    max-width: 100%;
    height: 100%;
}

.nutritional-values-prompt-button {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: #212121;
    padding: 5px 7px;
    color: #d3d3d3;
    font-size: 0.9em;
    height: 100%;
    width: fit-content;
}

.nutritional-values-prompt-button.active{
    background-color: #606060;
    color: #bdbdbd;
}

.nutritional-values-prompt-button:hover {
    cursor: pointer;
    background-color: #949494;
    color: rgb(32, 32, 32);
}

.chevron-down{
    margin-top: 3%;
    margin-left: 4px;
    padding: 0;
}

.chevron-up{
    margin-left: 4px;
    padding: 0;
}

.dropdown-container {
    position: relative;
}

.dropdown {
    margin-top: 2px;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 9999;
    border: solid 1px black;
}

.dropdown::-webkit-scrollbar {
    width: 0px
}

.dropdown-row {
    padding: 10px;
    white-space: initial;
}

.dropdown-row:hover {
    background-color: gray;
    cursor: pointer;
}

.cook-time-filter-container .filter {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4px;
    background-color: white;
    width: 100%;
}

.cook-time-filter-container .filter .title {
    display: inline-block;
    color: gray;
    font-size: 0.9em;
}

.cook-time-filter-container .input-field {
    display: inline-block;
    width: 40%;
    margin-left: 3%;
    border-style: none;
}

.cook-time-filter-container .input-field:focus,
.ingredient-filter-container .input-field:focus,
.cuisine-filter-container .input-field:focus {
    outline: none;
}

.cook-time-filter-container .cross-button {
    margin-left: auto;
    object-fit: contain;
    display: inline-block;
    content: url("./cross-button.png");
    width: 12px;
    padding: 2px;
    border-radius: 50%;
}

.cook-time-filter-container .cross-button:hover {
    background-color: gray;
    cursor: pointer;
}

.flex-break {
    display: inline-block;
}

.remove-all-filters-button {
    margin-left: auto;
    display: flex;
    align-items: center;
    background-color: rgb(33, 33, 33);
    padding: 5px 7px;
    color: #d3d3d3;
    font-size: 0.9em;
}

.remove-all-filters-button:hover {
    cursor: pointer;
    background-color: #949494;
    color: rgb(32, 32, 32);
}

.nutritional-values-filter-container {
    background-color: #cbe5ff;
}

.nutritional-values-filter-container form {
    margin: 0 auto;
    width: 70%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    height: 48px;
}

.filter.calorie, .filter.protein, .filter.carb, .filter.fat {
    display: flex;
    align-items: center;
}

.filter.calorie {
    width: 25%;
}

.filter.protein {
    width: 25%;
}

.filter.carb {
    width: 25%;
}

.filter.fat {
    width: 25%;
}

.filter.fat .title {
    margin-left: auto;
}

.filter.calorie .title {
    text-align: left;
}

.filter.carb .title {
    margin-left: auto;
}

.filter.protein .title {
    margin-left: auto;
}

.nutritional-values-filter-container .title {
    display: inline-block;
    font-size: 0.8em;
    text-align: right;
    transition: 1s ease;
}

.nutritional-values-filter-container .input-field {
    outline-style: none;
    display: inline-block;
    border-width: 0;
    height: 20px;
    margin-left: 3%;
    width: 65%;
    transition: 1s ease;
    max-width: 175px;
}


@media (max-width: 1450px) {
    .nutritional-values-filter-container .input-field {
        width: 60%;
    }
}

@media (max-width: 1215px) {
    .nutritional-values-filter-container .input-field {
        width: 57%;
        margin-left: 2%;
    }
}

@media (max-width: 1070px) {
    .nutritional-values-filter-container .input-field {
        width: 54%;
    }

    .nutritional-values-filter-container .title {
        font-size: 0.7em;
    }
}

@media (max-width: 890px) {
    .nutritional-values-prompt-button {
        margin-top: 5px;
        font-size: 0.6em;
        max-height: 40%;
    }

    .apply-filters-bar form {
        flex-wrap: wrap;
    }

    .ingredient-filter-container {
        max-width: 50%;
        max-height: 40%;
    }

    .cuisine-filter-container {
        max-width: 50%;
        max-height: 40%;
    }

    .cook-time-filter-container {
        margin-top: 5px;
        max-width: 55%;
        max-height: 40%;
    }

    .apply-filters-bar {
        height: 60px;
        padding: 7px 0px;
    }

    .filter-prompt-button {
        font-size: 0.6em;
    }

    .flex-break {
        flex-basis: 100%;
        height: 0;
    }

    .nutritional-values-filter-container .title {
        font-size: 0.7em;
        text-align: left;
    }

    .nutritional-values-filter-container form {
        height: 65px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 5px;
        grid-column-gap: 8px;
    }

    .filter.carb .title{
        margin: 0 0.65em 0 0;
    }

    .filter.calorie, .filter.protein, .filter.carb, .filter.fat {
        width: 100%;
    }

    .remove-all-filters-button {
        font-size: 0.6em;
    }

    .filter-value .value {
        font-size: 0.6em;
    }

    .input-field::placeholder {
        font-size: 0.6em;
    }

    .filter-prompt-button .counter {
        height: 8px;
        width: 8px;
        padding: 1px;
        line-height: 120%;
        font-size: 0.6em;
    }

    .remove-all-filters-button {
        font-size: 0.6em;
        height: 40%;
    }

    .cook-time-filter-container .cross-button {
        width: 10px;
    }

    .ingredient-filter-container, .cuisine-filter-container, .cook-time-filter-container {
        margin-right: 2%;
    }

    .cook-time-filter-container .filter .title {
        font-size: 0.6em;
    }

    .cook-time-filter-container .input-field {
        font-size: 0.6em;
    }
}