.filtered-values-bar {
    background-color: #a6aec0;
    padding: 8px 10px;
}

.filter-value {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    margin-right: 5px;
    background-color: #859bca;
    border-radius: 3px;
}

.filter-value .value {
    display: inline-block;
    vertical-align: middle;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.filter-value .cross-button {
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
    content: url("./cross-button.png");
    width: 10px;
    height: 10px;
    margin: 0px 2px 0px 5px;
    padding: 2px;
    border-radius: 50%;
}

.filter-value .cross-button:hover {
    background-color: gray;
    cursor: pointer;
}