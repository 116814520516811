/* Saved recipes page */
.saved-recipes .content {
    margin: 0 auto;
    width: 70%;
}

.unsave-all-recipes {
    color: white;
    display: inline-block;
    background-color: black;
    padding: 5px 10px;
    width: auto;
    margin-bottom: 15px;
}

.unsave-all-recipes:hover {
    background-color: #aaa9c1;
    color: black;
    cursor: pointer;
}

.no-recipes-message {
    margin: 10px;
}